import { lazy } from "react";

export const SmartStackerPage = lazy(
  () =>
    import(/* webpackChunkName: "cont" */ "module/propStack/components/SmartStackerPage")
);

export const PropSearchPage = lazy(
  () =>
    import(/* webpackChunkName: "cont" */ "module/propSearch/components/PropSearchPage")
);

export const ProspectsSearch = lazy(
  () =>
    import(
      /* webpackChunkName: "cont" */ "module/campaign/components/ProspectsSearch/ProspectsSearch"
    )
);

export const CampaignFoldersPage = lazy(
  () =>
    import(
      /* webpackChunkName: "cont" */ "module/campaign/components/CampaignFoldersPage/CampaignFoldersPage"
    )
);

export const SupportPage = lazy(
  () => import(/* webpackChunkName: "cont" */ "module/main/components/SupportPage")
);

export const UseDesktopPage = lazy(
  () => import(/* webpackChunkName: "cont" */ "module/main/components/UseDesktopPage")
);

export const SmsPrintViewList = lazy(
  () => import(/* webpackChunkName: "cont" */ "module/main/components/SmsPrintView")
);

export const LoginPage = lazy(
  () =>
    import(/* webpackChunkName: "cont" */ "module/main/components/LoginPage/LoginPage")
);

export const CampaignsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "cont" */ "module/campaign/components/CampaignsPage/CampaignsPage"
    )
);

export const CampaignDetailsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "cont" */ "module/campaign/components/CampaignDetailsPage/CampaignDetailsPage"
    )
);

export const ProspectDetailsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "cont" */ "module/campaign/components/ProspectDetailsPage/ProspectDetailsPage"
    )
);

export const DesktopCampaignsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "cont" */ "module/campaign/components/DesktopCampaignsPage/DesktopCampaignsPage"
    )
);

export const SkipTracePage = lazy(
  () =>
    import(
      /* webpackChunkName: "cont" */ "module/skipTrace/components/SkipTracePage/SkipTracePage"
    )
);

export const AccountSettingsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "cont" */ "module/main/components/AccountSettingsPage/AccountSettingsPage"
    )
);

export const DesktopCampaignDetailPage = lazy(
  () =>
    import(
      /* webpackChunkName: "cont" */ "module/campaign/components/DesktopCampaignDetailPage/DesktopCampaignDetailPage"
    )
);
export const NewMessagesPage = lazy(
  () =>
    import(
      /* webpackChunkName: "cont" */ "module/campaign/components/NewMessagesPage/NewMessagesPage"
    )
);

export const ActiveCallPage = lazy(
  () =>
    import(
      /* webpackChunkName: "cont" */ "module/common/components/ActiveCall/ActiveCallPage"
    )
);

export const PhoneNumberManagerPage = lazy(
  () =>
    import(
      /* webpackChunkName: "cont" */ "module/common/components/PhoneNumberManager/PhoneNumberManagerPage/PhoneNumberManagerPage"
    )
);
export const SmsManagerPage = lazy(
  () =>
    import(
      /* webpackChunkName: "cont" */ "module/common/components/SmsManager/SmsManagerPage"
    )
);

export const DesktopProspectDetailsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "cont" */ "module/campaign/components/DesktopProspectDetailsPage/DesktopProspectDetailsPage"
    )
);

export const StepsContainer = lazy(
  () =>
    import(/* webpackChunkName: "cont" */ "module/common/components/SignUpStepsContainer")
);

export const RegistrationPage = lazy(
  () =>
    import(
      /* webpackChunkName: "cont" */ "module/main/components/Registration/RegistrationPage"
    )
);

export const InvitationPage = lazy(
  () => import(/* webpackChunkName: "cont" */ "module/main/components/InvitationPage")
);

export const ForgotPassword = lazy(
  () =>
    import(
      /* webpackChunkName: "cont" */ "module/main/components/ForgotPassword/ForgotPassword"
    )
);

export const ResetPasswordPage = lazy(
  () => import(/* webpackChunkName: "cont" */ "module/main/components/ResetPassword")
);
