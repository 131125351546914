import { createSelector as createCachedSelector } from "reselect";
import { createSelectorContext } from "../redux-helpers";
import { IMarket } from "./interfaces";
import { Providers } from "module/common/helpers/variables";
import { getCompanyData } from "../Company/selectors";
import { validateMarketIsActive } from "./utils";
import { ICompany } from "../Company/interfaces";

const createSelector = createSelectorContext(["markets"]);

export const marketsList = createSelector("markets");
export const marketsCount = createSelector("count");
export const marketsStatus = createSelector("status");
export const marketsError = createSelector("error");

export const selectMarketsWithCampaigns = createCachedSelector(
  marketsList,
  (markets: IMarket[]) => markets.filter((market) => market.campaignCount > 0)
);

export const selectActiveMarkets = createCachedSelector(
  marketsList,
  (markets: IMarket[]) =>
    markets
      .filter((market) => validateMarketIsActive(market))
      .sort((market1, market2) => market1.name.localeCompare(market2.name))
);

export const selectHasActiveMarkets = createCachedSelector(
  selectActiveMarkets,
  (markets: IMarket[]) => markets.length > 0
);

export const companyHasInteliquentMarket = createCachedSelector(
  selectActiveMarkets,
  (markets: IMarket[]) => markets.some((m) => m.providerId === Providers.inteliquent)
);

export const companyHasTwilioMarket = createCachedSelector(
  selectActiveMarkets,
  (markets: IMarket[]) => markets.some((m) => m.providerId === Providers.twilio)
);

export const marketsWithoutTelephonyIntegrations = createCachedSelector(
  marketsList,
  getCompanyData,
  (markets: IMarket[], company: ICompany) => {
    // hasCompanySpecifiedTelephonyIntegration should be universal but right now it's
    // just tied to twilio
    const isTwilioEnabled = company.hasCompanySpecifiedTelephonyIntegration;

    let updatedMarkets = markets;
    if (isTwilioEnabled) {
      updatedMarkets = markets
        .sort((market1, market2) => Number(market2.isActive) - Number(market1.isActive))
        .filter((market) => market.name !== "Twilio");
    }
    return [...updatedMarkets];
  }
);
