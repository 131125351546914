import { SubscriptionType } from "module/common/helpers/variables";
import { IPlan } from "../store/SubscriptionStore/interfaces";
import { Location } from "history";

interface ManagedMarketPayload {
  primaryAreaCode: string;
  state: string;
  alternateAreaCode: string;
  name: string;
  callForwardingNumber: string;
}

interface SelfManagedMarketPayload {
  areaCodes: string[];
  telephonyId: string;
}

// TODO-814: Doesn't work anymore
export const getMarketBillingRoute = (
  marketPayload: ManagedMarketPayload | SelfManagedMarketPayload
) =>
  ({
    pathname: "/account-settings/billing",
    state: marketPayload,
  }) as const;

export const version2 = "-v2";

const desktopCampaignRoute = {
  path: "/campaign/:type/:id/details",
  getPath: (type: string, id: string) => `/campaign/${type}/${id}/details`,
} as const;

export const links = {
  accountSettings: "/account-settings",
  propSearch: "/prop-search",
  skipTrace: "/skip-trace",
  phoneManager: "/phone-manager",
  templates: {
    path: "/templates-manager",
    name: "templates-manager",
  },
  subscription: "/account-settings/subscription",
  campaigns: {
    list: "/campaigns/all",
    desktop: desktopCampaignRoute,
    desktopV2: {
      path: desktopCampaignRoute.path + version2,
      getPath: (type: string, id: string) =>
        desktopCampaignRoute.getPath(type, id) + version2,
    },
  },
} as const;

export const signUpSubscriptionRoute = {
  pathname: links.subscription,
  state: {
    subscriptionInfo: { type: SubscriptionType.SignUp, title: "Sign Up" },
  },
} as const;

export const upgradeSubscriptionRoute = {
  pathname: links.subscription,
  state: {
    subscriptionInfo: { type: SubscriptionType.Upgrade, title: "Upgrade" },
  },
} as const;

export const getUpgradeFromPreviewRoute = (plan?: IPlan) =>
  ({
    pathname: links.subscription,
    state: {
      subscriptionInfo: {
        type: SubscriptionType.UpgradeFromPreview,
        title: `Upgrade to ${plan?.description}`,
      },
      selectedPreviewPlan: plan,
    },
  }) as const;

export const tenDLCSubscriptionRoute = {
  pathname: links.subscription,
  state: {
    subscriptionInfo: { type: SubscriptionType.TenDLC, title: "Register for 10DLC" },
  },
} as const;

export const changeDocumentTitle = (location: Location) => {
  switch (location.pathname.split("/")[1]) {
    // Cases are ordered based on Page-views according to GA
    case "all-unread":
      document.title = "New messages | Lead Sherpa";
      break;
    case "login":
      document.title = "Login | Lead Sherpa";
      break;
    case "campaigns":
      document.title = "Campaigns | Lead Sherpa";
      break;
    case "dashboard":
      document.title = "Dashboard | Lead Sherpa";
      break;
    case "skip-trace":
      document.title = "Skip Trace | Lead Sherpa";
      break;
    case "register":
      document.title = "Register | Lead Sherpa";
      break;
    case "stacker":
      document.title = "PropStack™ | Lead Sherpa";
      break;
    case "account-settings":
      document.title = "Settings | Lead Sherpa";
      break;
    case "phone-manager":
      document.title = "Phone Manager | Lead Sherpa";
      break;
    case links.templates.name:
      document.title = "Template Manager | Lead Sherpa";
      break;
    case "support":
      document.title = "Support | Lead Sherpa";
      break;
    default:
      document.title = "Lead Sherpa";
      break;
  }
};
