import React, { useState } from "react";
import { Button } from "reactstrap";

import {
  Col,
  TopSection,
  BodySection,
  HeaderImg,
  ButtonRow,
  TitleWrapper,
  Title,
  SubTitle,
  TopRow,
  CardRow,
  FooterRow,
  Help,
} from "./styles";
import Card from "../../../../Subscription/LandingPage/shared/Card";
import CircledIcon from "../../../../CircledIcon";
import BlockedMessagePage from "../../BlockedMessagePage";
import { FindOutMoreLink, GuideLink } from "module/common/styles";

const GenericLandingPage = ({
  title,
  subTitle,
  btnText = "Get Started Now",
  headerImg,
  useChildRow = false,
  helpLink = "",
  cards,
  SignUpPage = null,
  redirect,
}) => {
  const [open, setOpen] = useState(false);
  if (open) {
    return (
      <BlockedMessagePage noHeader="Activate Your Account">
        <SignUpPage />
      </BlockedMessagePage>
    );
  }

  return (
    <div>
      <TopSection>
        <TopRow>
          <Col>
            <TitleWrapper>
              <Title>{title}</Title>
              <SubTitle className="mt-2">{subTitle}</SubTitle>
            </TitleWrapper>
            <ButtonRow>
              <Button
                className="mr-2"
                size="md"
                color="primary"
                data-test="start-sms-signup-btn"
                onClick={() => (redirect ? redirect() : setOpen(true))}
              >
                {btnText}
              </Button>
              {helpLink ? (
                <FindOutMoreLink
                  href={helpLink}
                  target="_blank"
                  data-test="find-out-more-link"
                >
                  Find Out More
                </FindOutMoreLink>
              ) : null}
            </ButtonRow>
          </Col>
          <HeaderImg src={headerImg} />
        </TopRow>
      </TopSection>
      {cards.length ? (
        <BodySection>
          <Col>
            {cards.map((cardRow, i) => {
              return (
                <CardRow key={i} className="flex-md-row" mt={i !== 0}>
                  {cardRow.map((cardChildRow, j) => {
                    if (useChildRow) {
                      return (
                        <CardRow key={j} className="flex-sm-row" mt={j !== 0} isChildRow>
                          {cardChildRow.map((card, k) => {
                            return (
                              <Card
                                key={k}
                                icon={<CircledIcon type={card.icon} />}
                                title={card.title}
                                smallPadding={card.smallPadding}
                                body={card.body}
                                isChildRow
                                isMarginLeft={j !== 0}
                              />
                            );
                          })}
                        </CardRow>
                      );
                    }
                    const card = cardChildRow;
                    return (
                      <Card
                        key={j}
                        icon={<CircledIcon type={card.icon} />}
                        title={card.title}
                        smallPadding={card.smallPadding}
                        body={card.body}
                      />
                    );
                  })}
                </CardRow>
              );
            })}
            <FooterRow>
              <Help>Need some help?</Help>
              <GuideLink
                href="https://leadsherpa.freshdesk.com/support/solutions/44000815009"
                target="_blank"
              >
                All Guides and Tutorials <strong>{`>`}</strong>
              </GuideLink>
            </FooterRow>
          </Col>
        </BodySection>
      ) : null}
    </div>
  );
};

export default GenericLandingPage;
