import { produce } from "immer";
// actions
import {
  FETCH_PROP_SEARCH_RESULTS,
  SET_PROP_SEARCH_STATUS,
  SET_PROP_SEARCH_OPTED_IN,
  SET_PROP_SEARCH_CURRENT_FILTERS,
  SET_CURRENT_MAP_PROP_SEARCH_LOOKUP,
} from "./actions";
import { arrayToMapIndex } from "../utils";
// utils
import { Initial, Success } from "module/common/helpers/variables";
import { PropSearchLookup } from "./api";
import { PropSearchParameters } from "./api";

interface IState {
  propSearchResults: Record<number, PropSearchLookup>;
  status: string;
  isOptedIn: boolean | null;
  currentFilters: PropSearchParameters;
  currentMapPropSearchLookup: PropSearchLookup | null;
}

const state: IState = {
  propSearchResults: {},
  status: Initial,
  isOptedIn: null,
  currentFilters: {},
  currentMapPropSearchLookup: null,
};

export const path = ["PropSearch"];

const reducer = produce((draft: IState, action: any) => {
  switch (action.type) {
    case FETCH_PROP_SEARCH_RESULTS: {
      draft.propSearchResults = {
        ...draft.propSearchResults,
        ...arrayToMapIndex("id", action.payload),
      };
      draft.status = Success;
      break;
    }
    case SET_PROP_SEARCH_STATUS: {
      draft.status = action.payload;
      break;
    }
    case SET_PROP_SEARCH_OPTED_IN: {
      draft.isOptedIn = action.payload;
      break;
    }
    case SET_PROP_SEARCH_CURRENT_FILTERS: {
      draft.currentFilters = action.payload;
      break;
    }
    case SET_CURRENT_MAP_PROP_SEARCH_LOOKUP: {
      draft.currentMapPropSearchLookup = action.payload;
      break;
    }
  }
}, state);

export default reducer;
