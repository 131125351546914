import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Form } from "reactstrap";
import { useForm, FormContext } from "react-hook-form";

import FormFieldsRenderer, { StyledError } from "../formFields/FormFieldsRenderer";
import { LoadingButton } from "../Buttons";

// styled-components
import { ButtonsContainer } from "assets/styles/sc/layout";

const FormRenderer = ({
  fields = [],
  overrides = {},
  onSubmit: submit,
  submitButton,
  defaultValues = {},
  buttonProps = { name: "Submit", block: false, className: "", "data-test": "" },
  formWrapper = null,
  formId = "",
  hasRemoteSubmitBtn = false,
  onBack,
  onCancel,
  ignoreIdCheck = false,
  children,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [nonFieldErrors, setNonFieldErrors] = useState("");
  const methods = useForm({ defaultValues: defaultValues });

  useEffect(() => {
    methods.reset(defaultValues);
    // eslint-disable-next-line
  }, [defaultValues]);

  const onSubmit = (body, e) => {
    e.stopPropagation();
    if (!ignoreIdCheck && e.target.id !== formId) return;
    setSubmitting(true);
    submit({
      setError: methods.setError,
      data: body,
      setServerError: setNonFieldErrors,
      setSubmittingState: setSubmitting,
      resetForm: methods.reset,
    });
  };

  const Wrapper = formWrapper ? formWrapper : React.Fragment;

  return (
    <FormContext {...methods}>
      <Form id={formId} onSubmit={methods.handleSubmit(onSubmit)}>
        <Wrapper>
          <FormFieldsRenderer
            control={methods.control}
            groupedFields={fields}
            overrides={overrides}
          />
          {nonFieldErrors.length ? (
            <div className="text-center mb-1" data-test="non-field-error">
              {nonFieldErrors.map((error, index) => (
                <StyledError key={index}>{error}</StyledError>
              ))}
            </div>
          ) : null}
        </Wrapper>
        {hasRemoteSubmitBtn ? null : submitButton ? (
          submitButton({ submittingState: submitting })
        ) : (
          <ButtonsContainer>
            {onBack && (
              <Button
                disabled={submitting}
                className="back-button"
                type="button"
                onClick={onBack}
              >
                Back{" "}
              </Button>
            )}
            {onCancel && (
              <Button className="cancel-button" type="button" onClick={onCancel}>
                Cancel{" "}
              </Button>
            )}
            <LoadingButton
              color="primary"
              className={`${buttonProps.className}`}
              type="submit"
              data-test="form-submit"
              value={buttonProps.name}
              loading={submitting}
              disabled={submitting}
              {...buttonProps}
            >
              {buttonProps.name}
            </LoadingButton>
          </ButtonsContainer>
        )}
        {children}
      </Form>
    </FormContext>
  );
};

FormRenderer.propTypes = {
  /** The fields that will be part of the form */
  fields: PropTypes.array.isRequired,
  /** Any overrides to be passed down to the FormFieldsRenderer styles. */
  overrides: PropTypes.object,
  /** The submit action of the form */
  onSubmit: PropTypes.func.isRequired,
  /** Custom Submit button to override the default */
  submitButton: PropTypes.func,
  /** Default values to prepopulate the form on display */
  defaultValues: PropTypes.object,
  /** Additional props for the Submit button */
  buttonProps: PropTypes.object,
  /** Custom Styled Wrapper on the form */
  formWrapper: PropTypes.elementType,
  /** Id of the form (used in conjunction with hasRemoteSubmitBtn) */
  formId: PropTypes.string,
  /** Used mainly to determine if the form submit is being controlled outside of the form */
  hasRemoteSubmitBtn: PropTypes.bool,
  /** Used to ignore check if the target id is the same as the formId */
  ignoreIdCheck: PropTypes.bool,
  /** The back action of the form (if applicable) */
  onBack: PropTypes.func,
  /** The cancel action of the form (if applicable) */
  onCancel: PropTypes.func,
  /** The cancel action of the form (if applicable) */
  children: PropTypes.node,
};

FormRenderer.defaultProps = {
  fields: [],
  overrides: {},
  defaultValues: {},
  buttonProps: { name: "Submit", block: false, className: "mt-2" },
  hasRemoteSubmitBtn: false,
  ignoreIdCheck: false,
};

export default FormRenderer;
