import React from "react";
import {
  SkipTracePage,
  SupportPage,
  AccountSettingsPage,
  PhoneNumberManagerPage,
  SmsManagerPage,
  SmartStackerPage,
  UseDesktopPage,
  ProspectsSearch,
  CampaignFoldersPage,
  SmsPrintViewList,
  LoginPage,
  CampaignsPage,
  CampaignDetailsPage,
  ProspectDetailsPage,
  DesktopCampaignsPage,
  DesktopCampaignDetailPage,
  NewMessagesPage,
  ActiveCallPage,
  DesktopProspectDetailsPage,
  RegistrationPage,
  InvitationPage,
  ForgotPassword,
  ResetPasswordPage,
  StepsContainer,
  PropSearchPage,
} from "./lazyImports";
import { links } from "./utils";
import { Features } from "module/common/helpers/variables";

//turn to lazy import later
import UnreadMessagePage from "module/campaign/components/UnreadMessagePage/UnreadMessagePage";

// permissions
import { ACCOUNT_SETTINGS_VIEW_PAGE } from "../permissions/accountSettings";
import { SKIPTRACE_VIEW_PAGE } from "../permissions/skiptrace";
import { NUMBER_MANAGER_VIEW_PAGE } from "../permissions/phoneNumberManager";
import { SMS_TEMPLATES_VIEW_PAGE } from "../permissions/smsTemplates";
import { SMART_STACKER_VIEW_PAGE } from "../permissions/smartStacker";

// special components
import NewNavIcon from "module/main/components/NewNavIcon";

export const mobilePaths = [
  "/",
  "/markets",
  "/prospects",
  "/prospect",
  "/support",
  "/new-messages",
  "/active-call",
  "/property-stacker-mobile",
  "/skip-trace-mobile",
];

export const desktopPaths = [
  "/all-unread",
  "/campaigns",
  "/skip-trace",
  "/campaign",
  "/account-settings",
  "/phone-manager",
  links.templates.path,
  "/support",
  "/prospect",
  "/stacker",
  "/prop-search",
];

export const campaignsSequencesPath = "/campaigns/sequences";
export const smsRemindersSubPath = "/sms-reminder";
export const callRemindersSubPath = "/call-reminder";

export const mobileRoutes = [
  {
    path: "/",
    name: "SMS Campaigns",
    navIcon: "bullhorn",
    alt: "",
    exact: true,
    protectedRoute: true,
    component: CampaignFoldersPage,
  },
  {
    path: "/prospects",
    name: "Prospects",
    navIcon: "search",
    alt: "Prospects",
    exact: true,
    protectedRoute: true,
    component: ProspectsSearch,
  },
  {
    path: "/support",
    name: "Support",
    navIcon: "question-circle",
    alt: "support",
    exact: true,
    component: SupportPage,
  },
  {
    path: "/new-messages",
    name: "New Messages",
    navIcon: "comment-dots",
    alt: "Unread Messages",
    exact: true,
    protectedRoute: true,
    component: NewMessagesPage,
  },
  {
    path: "/property-stacker-mobile",
    name: "PropStack™",
    navIcon: "sort-amount-down",
    alt: "Property Stacker",
    exact: true,
    protectedRoute: true,
    component: UseDesktopPage,
  },
  {
    path: "/skip-trace-mobile",
    name: "SkipTrace",
    navIcon: "address-book",
    alt: "Skip Trace",
    exact: true,
    protectedRoute: true,
    component: UseDesktopPage,
  },
  {
    path: "/prospect/:prospectId/details",
    name: "Prospect Details",
    alt: "Prospect Details",
    exact: true,
    protectedRoute: true,
    component: ProspectDetailsPage,
  },
  {
    path: "/markets/:marketId/campaigns",
    name: "Markets",
    alt: "Markets",
    exact: true,
    protectedRoute: true,
    component: CampaignsPage,
  },
  {
    path: "/markets/:marketId/campaigns/:campaignId/details",
    name: "Campaigns Details",
    alt: "Campaigns Details",
    exact: true,
    protectedRoute: true,
    component: CampaignDetailsPage,
  },
  {
    path: "/active-call",
    name: "Active Call",
    alt: "Active Call",
    exact: true,
    protectedRoute: true,
    component: ActiveCallPage,
  },
];

export const sharedRoutes = [
  {
    path: "/login",
    name: "Login",
    alt: "Login",
    exact: true,
    protectedRoute: false,
    component: LoginPage,
  },
  {
    path: "/register",
    name: "Register",
    alt: "Register",
    protectedRoute: false,
    component: RegistrationPage,
    exact: true,
  },
  {
    path: "/invite",
    name: "Invite",
    alt: "Invite",
    protectedRoute: false,
    component: InvitationPage,
    exact: true,
  },
  {
    path: "/forgot-pw",
    name: "Forgot Password",
    alt: "Forgot Password",
    protectedRoute: false,
    component: ForgotPassword,
    exact: true,
  },
  {
    path: "/password-reset",
    name: "Password Reset",
    alt: "Password Reset",
    protectedRoute: false,
    component: ResetPasswordPage,
    exact: true,
  },

  {
    path: "/public/sms/:token",
    name: "Print Prospect",
    alt: "Print Prospect",
    protectedRoute: false,
    component: SmsPrintViewList,
    exact: true,
  },
];

export const desktopRoutes = [
  //protected routes
  {
    path: "/all-unread",
    name: "All Unread",
    alt: "All Unread",
    exact: true,
    protectedRoute: true,
    component: UnreadMessagePage,
  },
  {
    path: "/campaigns/:type?/:id?/",
    name: "Campaigns",
    alt: "Campaigns",
    exact: true,
    protectedRoute: true,
    component: DesktopCampaignsPage,
  },
  {
    path: links.campaigns.desktop.path,
    name: "Campaign Details",
    alt: "Campaign Details",
    exact: true,
    protectedRoute: true,
    component: DesktopCampaignDetailPage,
  },
  {
    path: links.campaigns.desktopV2.path,
    name: "Campaign Details",
    alt: "Campaign Details",
    exact: true,
    protectedRoute: true,
    protectedFeature: Features.SmsRedesign,
    component: DesktopCampaignDetailPage,
  },
  {
    path: "/stacker",
    name: "PropStack™",
    alt: "PropStack™",
    navIcon: "sort-amount-down",
    exact: true,
    protectedRoute: true,
    component: SmartStackerPage,
    permissionProps: {
      checkAllPermissions: true,
      permission: SMART_STACKER_VIEW_PAGE,
      pageName: "stacker",
    },
  },
  {
    path: "/prop-search",
    name: "PropSearch",
    alt: "PropSearch",
    navIcon: <NewNavIcon icon="magnifying-glass-location" />,
    exact: true,
    protectedRoute: true,
    component: PropSearchPage,
  },
  {
    path: "/skip-trace",
    name: "Skip Trace",
    alt: "Skip Trace",
    navIcon: "address-book",
    exact: true,
    protectedRoute: true,
    component: SkipTracePage,
    permissionProps: {
      checkAllPermissions: true,
      permission: SKIPTRACE_VIEW_PAGE,
      pageName: "skiptrace",
    },
  },
  {
    path: "/phone-manager",
    name: "Phone Manager",
    alt: "Phone Manager",
    navIcon: "phone",
    exact: true,
    protectedRoute: true,
    component: PhoneNumberManagerPage,
    permissionProps: {
      checkAllPermissions: true,
      permission: NUMBER_MANAGER_VIEW_PAGE,
      pageName: "phone-manager",
    },
  },
  {
    path: links.templates.path,
    name: "Templates Manager",
    alt: "Templates Manager",
    navIcon: "pencil-alt",
    exact: true,
    protectedRoute: true,
    component: SmsManagerPage,
    permissionProps: {
      checkAllPermissions: true,
      permission: SMS_TEMPLATES_VIEW_PAGE,
      pageName: "sms-templates",
    },
  },
  {
    path: "/support",
    name: "Support",
    alt: "Support",
    navIcon: "question-circle",
    exact: true,
    protectedRoute: true,
    component: SupportPage,
    permissionProps: {}, // leave blank to avoid checking permissions
  },
  {
    path: "/account-settings",
    name: "Account Settings",
    alt: "Account Settings",
    navIcon: "user-cog",
    exact: true,
    protectedRoute: true,
    component: AccountSettingsPage,
    checkPermissions: true,
    permissionProps: {
      checkAllPermissions: true,
      permission: ACCOUNT_SETTINGS_VIEW_PAGE,
      pageName: "account-settings",
    },
  },
  {
    path: links.subscription,
    name: "Subscription",
    alt: "Subscription",
    exact: true,
    protectedRoute: true,
    component: StepsContainer,
  },
  {
    path: "/prospect/:prospectId/details",
    name: "Prospect Details",
    alt: "Desktop Prospect Details",
    exact: true,
    protectedRoute: true,
    component: DesktopProspectDetailsPage,
  },
];
