export interface DynamicConfig {
  REACT_APP_DYNAMIC_ENV: "local" | "staging" | "production" | "ephemeral";
  REACT_APP_DYNAMIC_REPORT_ERRORS: "true" | "false";
  REACT_APP_DYNAMIC_BASE_URL: string;
  REACT_APP_DYNAMIC_TRACKERS_MODE: "trackers" | "no-trackers";
  REACT_APP_DYNAMIC_SENTRY_RELEASE: string;
  REACT_APP_DYNAMIC_ENABLE_APN_ST: "true" | "false";
  LEADSHERPA_IMAGE_TAG: string;
  REACT_APP_DYNAMIC_GOOGLE_API_KEY: string;
  REACT_APP_DYNAMIC_MAPBOX_ACCESS_TOKEN: string;
}

export const defaultConfig: DynamicConfig = {
  REACT_APP_DYNAMIC_ENV: process.env.REACT_APP_ENV,
  REACT_APP_DYNAMIC_REPORT_ERRORS: process.env.REACT_APP_REPORT_ERRORS,
  REACT_APP_DYNAMIC_BASE_URL: process.env.REACT_APP_BASE_URL,
  REACT_APP_DYNAMIC_TRACKERS_MODE: process.env.REACT_APP_TRACKERS_MODE,
  REACT_APP_DYNAMIC_SENTRY_RELEASE: process.env.REACT_APP_SENTRY_RELEASE,
  REACT_APP_DYNAMIC_ENABLE_APN_ST: process.env.REACT_APP_ENABLE_APN_ST,
  REACT_APP_DYNAMIC_GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
  REACT_APP_DYNAMIC_MAPBOX_ACCESS_TOKEN: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  LEADSHERPA_IMAGE_TAG: "",
};

class GlobalDynamicConfig {
  private __config: DynamicConfig = defaultConfig;
  private hasBeenInjected = false;

  get config() {
    if (!this.hasBeenInjected) {
      throw new Error("Global config has not been injected yet.");
    }
    return this.__config;
  }
  set config(config: DynamicConfig) {
    if (this.hasBeenInjected) {
      throw new Error("Global config has already been injected.");
    }
    this.__config = config;
    this.hasBeenInjected = true;
  }
}

export const globalDynamicConfig = new GlobalDynamicConfig();

export const dynamicConfigUrl = "dynamicConfig.json";
