import { createAction } from "../redux-helpers";

export const FETCH_PROP_SEARCH_RESULTS = "FETCH_PROP_SEARCH_RESULTS";
export const fetchPropSearchResults = createAction(FETCH_PROP_SEARCH_RESULTS);

export const SET_PROP_SEARCH_STATUS = "SET_PROP_SEARCH_STATUS";
export const setPropSearchStatus = createAction(SET_PROP_SEARCH_STATUS);

export const SET_PROP_SEARCH_OPTED_IN = "SET_PROP_SEARCH_OPTED_IN";
export const setPropSearchOptedIn = createAction(SET_PROP_SEARCH_OPTED_IN);

export const SET_PROP_SEARCH_CURRENT_FILTERS = "SET_PROP_SEARCH_CURRENT_FILTERS";
export const setPropSearchCurrentFilters = createAction(SET_PROP_SEARCH_CURRENT_FILTERS);

export const SET_CURRENT_MAP_PROP_SEARCH_LOOKUP = "SET_CURRENT_MAP_PROP_SEARCH_LOOKUP";
export const setCurrentMapPropSearchLookup = createAction(
  SET_CURRENT_MAP_PROP_SEARCH_LOOKUP
);
